import React, { useState, useEffect } from "react";

const Cookie = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if cookies have already been accepted
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    // Save the user's acceptance to localStorage
    localStorage.setItem("cookiesAccepted", true);
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div className="fixed bottom-5 left-5 lg:w-[40%] mr-5 rounded-3xl bg-[#f7f8f8] text-black lg:p-6 p-4 justify-between items-center z-50">
        <p className="text-base">
          This website stores cookies on your computer. These cookies are used
          to collect information about how you interact with our website and
          allow us to remember you. We use this information in order to improve
          and customize your browsing experience and for analytics and metrics
          about our visitors both on this website and other media. To find out
          more about the cookies we use, see our{" "}
          <span>
            <a href="/termsofservice" className="underline">
              Terms of Service
            </a>
          </span>
          .
        </p>
        <button
          onClick={handleAccept}
          className="bg-secondary hover:bg-green-600 text-white px-4 py-2 rounded-lg mt-10"
        >
          Accept Cookies
        </button>
      </div>
    )
  );
};

export default Cookie;
