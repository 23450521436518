import React, { useState } from "react";
import { FadeIn } from "react-slide-fade-in";
import Partner1 from "../Assets/Img/partner1.png";
import Partner2 from "../Assets/Img/partner2.png";
import Partner3 from "../Assets/Img/partner3.png";
import Partner4 from "../Assets/Img/partner4.png";
import Partner5 from "../Assets/Img/partner5.png";
import Partner6 from "../Assets/Img/partner6.png";
import Partner7 from "../Assets/Img/partner7.png";
import Partner8 from "../Assets/Img/partner8.png";
import Partner9 from "../Assets/Img/partner9.png";
import Partner11 from "../Assets/Img/partner11.png";
import Partner12 from "../Assets/Img/partner12.png";
import Person1 from "../Assets/Img/person1.jpg";
import Person2 from "../Assets/Img/person22.jpg";
import Person3 from "../Assets/Img/person2.jpg";
import Person4 from "../Assets/Img/person4.png";
import Person6 from "../Assets/Img/person6.png";
import Person81 from "../Assets/Img/person8.png";
import Person9 from "../Assets/Img/person9-2.png";
import Person11 from "../Assets/Img/person11.png";
import Person12 from "../Assets/Img/person12.png";
import Person14 from "../Assets/Img/person14.png";
import Person15 from "../Assets/Img/person15.png";
import Person17 from "../Assets/Img/person17.png";
import Person19 from "../Assets/Img/person19.png";
import Person20 from "../Assets/Img/person20.png";
import Person23 from "../Assets/Img/person23.png";
import { MdRocketLaunch } from "react-icons/md";
import { FaChevronUp, FaChevronDown } from "react-icons/fa6";
import { FaBuilding, FaCheck, FaTruck } from "react-icons/fa";
import { GiTwoCoins, GiTreeBranch, GiGroundSprout } from "react-icons/gi";
import Team from "../Components/Team";

import { BsCurrencyExchange } from "react-icons/bs";
// import { useTimer } from "../Controller/Constants";
export default function Roadmap() {
  // const { days, hours, minutes, seconds } = useTimer();
  const [q223Visible, setQ223Visible] = useState(false);
  const [q323Visible, setQ323Visible] = useState(false);
  const [q423Visible, setQ423Visible] = useState(false);
  const [q124Visible, setQ124Visible] = useState(false);
  const [q224Visible, setQ224Visible] = useState(false);
  const [q324Visible, setQ324Visible] = useState(false);
  const [q424Visible, setQ424Visible] = useState(false);
  const [q125Visible, setQ125Visible] = useState(false);
  const [q225Visible, setQ225Visible] = useState(false);
  const [q261Visible, setQ261Visible] = useState(false);
  const [q262Visible, setQ262Visible] = useState(false);
  const [q271Visible, setQ271Visible] = useState(false);
  const [q272Visible, setQ272Visible] = useState(false);

  const toggleVisibility = (setVisible, currentVisibility) => {
    setVisible(!currentVisibility);
  };
  return (
    <section>
      <section>
        <div
          className="isolate bg-white hero-about lg:bg-center md:bg-top bg-[url('./Assets/Img/about-bg.jpg')] bg-center"
          style={{ backgroundRepeat: "no-repeat" }}
        >
          <main className="" style={{ maxWidth: "1400px", margin: "0 auto" }}>
            <div className="">
              <div className="p-10 lg:p-20 md:p-20 ">
                <div>
                  <div className="pt-20">
                    <FadeIn
                      from="left"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <p className="text-white lg:pb-24 md:pb-0 pb-20 lg:pt-0 md:pb-0 pt-10 text-right hero-heading">
                        roadmap.
                      </p>
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </section>

      <section
        className="lg:p-20 lg:pb-5 p-10 pb-5"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <p className="text-heading">
          <span className="text-secondary">Our</span> Achievements
        </p>
        <div className="mt-5 text-thin">
          <div className="w-2 h-14 bg-primary ml-5 rounded-t-2xl"></div>
          {/* Q2 2023 */}
          <div className="flex space-between">
            <div className="relative ">
              <div className="w-2 h-full bg-primary ml-5 "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 -top-2 flex justify-center items-center">
                <FaCheck className="text-white w-6 h-6" />
              </div>
            </div>

            <div className="lg:grid grid-cols-10 gap-10">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ223Visible, q223Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q2 2023
                  <span className="justify-center ml-3 lg:hidden">
                    {q223Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                <p>Research</p>
              </div>

              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q223Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      Research and Market analysis
                    </span>
                    <br />
                    We made a research and market analysis in Nigeria, Kenya and
                    Brazil.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Campaign for FAME</span> <br />
                    We campaigned for FAME in Ibadan University event,
                    Blockchain events in Nigeria. There was grassroot
                    onboarding. We established the Brazilian FAME Hub.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Q323 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5"></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <FaCheck className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ323Visible, q323Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q3 2023
                  <span className="justify-center ml-3 lg:hidden">
                    {q323Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                <p>Community Drive</p>
              </div>
              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q323Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      Interviews with our Focus group of 100 Nigerian farmers
                    </span>
                    <br />
                    Social awareness on social networks was made and we
                    facilitated X (Twitter) spaces about ReFi.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">FAME App Launch</span> <br />
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Marketplace</span> <br />
                    We launched a marketplace to facilitate trade and exchange
                    on FAME app. We added free posts, like and comment feature
                    to promote agriculture products.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">FAME global map</span> <br />
                    We launched the FAME global app to connect farmers and
                    facilitate communication globally.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      FAME Wallet (Custodial and non-custodial)
                    </span>
                    <br />
                    We launched the FAME wallet to sopport ALGO, FAME, USDC and
                    EURS
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      FAME Wallet (Custodial and non-custodial)
                    </span>
                    <br />
                    We launched the FAME wallet to sopport ALGO, FAME, USDC and
                    EURS
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Chat pay</span>
                    <br />
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold"> Secured communication</span>
                    <br />
                    We added security to regular chats and private group chat by
                    making them encrypted.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Q423 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5"></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <FaCheck className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ423Visible, q423Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q4 2023
                  <span className="justify-center ml-3 lg:hidden">
                    {q423Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                <p>FAME DAO launch</p>
              </div>
              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q423Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">DAO smart contract</span> <br />
                    Formalizing governance through a decentralized autonomous
                    organization based on the $FAME token
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Referendum</span> <br />
                    We introduced a decentralized voting mechanism for key
                    decisions.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Forum</span> <br />
                    We introduced the DAO forum where the community can debate
                    each proposal.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">NFDomain integration</span>
                    <br />
                    Send to X.algo addresses instead of the unreadable Wallet
                    addresses
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Treasury Management</span>
                    <br />
                    We implemented systems for managing the DAO's financial
                    resources.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Q124 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5 "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <FaCheck className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ124Visible, q124Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q1 2024
                  <span className="justify-center ml-3 lg:hidden">
                    {q124Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                <p>DeFi Rollout</p>
              </div>
              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q124Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Swap</span> <br />
                    We intoduced the swap feature to enable seamless exchange of
                    assets.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Lending</span> <br />
                    We launched USDC staking earing ~5% APR
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Q224 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5 "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <FaCheck className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ224Visible, q224Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q2 2024
                  <span className="justify-center ml-3 lg:hidden">
                    {q224Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                <p>Local Empowerment</p>
              </div>
              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q224Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">League DAOs</span> <br />
                    We established NFT based Decentralized Autonomous
                    Organizations at a local level to empower communities.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Translations</span> <br />
                    The FAME app is available globally and translate in 7
                    languages
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Q3 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5 "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <FaCheck className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20 w-full">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ324Visible, q324Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q3 2024
                  <span className="justify-center ml-3 lg:hidden">
                    {q324Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                <p>Compliance to EU regulations</p>
              </div>
              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q324Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      Full decentralization of all FAME Wallets
                    </span>
                    <br />
                    Offers, Demands
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">New Functionalities</span>
                    <br />
                    Market divides in two feeds - Offers and Demands
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      Village Crédit Agricole Incubator
                    </span>
                    <br />
                    {/* We created a database tailored for smart business solutions. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="lg:p-20 lg:pt-0 p-10 pt-0"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <p className="text-heading">
          <span className="text-secondary">Roadmaps</span> Project Development
        </p>

        <div className="mt-5 text-thin">
          <div className="w-2 h-14 bg-primary ml-5"></div>
          {/* Q4 2024 */}
          <div className="flex space-between">
            <div className="relative ">
              <div className="w-2 h-full bg-primary ml-5"></div>
              <div className="absolute bg-primary rounded-3xl w-12 h-12 -top-2 flex justify-center items-center animate-ping "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 -top-2 flex justify-center items-center">
                <MdRocketLaunch className="text-white w-6 h-6" />
              </div>
            </div>

            <div className="lg:grid grid-cols-10 gap-10">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ424Visible, q424Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q4 2024
                  <span className="justify-center ml-3 lg:hidden">
                    {q424Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                <p>AI integration for the agriculture sector </p>
              </div>
              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q424Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">FAME companion</span> <br />
                    AI for all from the FAME chat (LLama 3 model)
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Smart Biz Database</span> <br />
                    We created a database tailored for smart business solutions.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">AI B2B Matching Agent</span>{" "}
                    <br />
                    Development of an AI-based system to match businesses for
                    partnerships and collaborations started.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="text-3xl font-bold my-5 text-secondary">
            20<span className="text-primary">25</span>
          </p>
          {/* 2025 1 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5"></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-5 flex justify-center items-center z-[1]">
                <FaBuilding className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-5 w-full">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ125Visible, q125Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q1 - Q2
                  <span className="justify-center ml-3 lg:hidden">
                    {q125Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                {/* <p>DeFi services extension</p> */}
              </div>
              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q125Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Seed $500K</span>
                    <br />
                    {/* Opt-in optimization. */}
                  </p>
                </div>
                <br />
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">TEAM expansion:</span>
                    <br />
                    Product, designer, sales, marketing.
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Partnerships</span>
                    <br />
                    NGOs and farmers coops
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      Corporate suite Database to 4000 businesses
                    </span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">B2B AI agents</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">$ALGO staking</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Merge DAO/APP</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      Peer-to-peer cash payments
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* 2025 2 */}
          <div className="flex space-between ">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5"></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center z-[1]">
                <FaTruck className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ225Visible, q225Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q3 - Q4
                  <span className="justify-center ml-3 lg:hidden">
                    {q225Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                {/* <p>Community and Governance Enhancements</p> */}
              </div>
              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q225Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">FAME Visa mastercard</span>
                  </p>
                </div>

                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">$FARM token on exchanges</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">IOT for Supply Chain</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      Organic farm analytics on the MAP
                    </span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Delivery integrations</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold"> Smart contracts</span>
                    <br />
                    Letter of credit, Bill of lading, Airway bill.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="text-3xl font-bold my-5 text-secondary">
            20<span className="text-primary">26</span>
          </p>
          {/* 2026 1 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5 "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-5 flex justify-center items-center z-[1]">
                <GiGroundSprout className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-5">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ261Visible, q261Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q1 - Q2
                  <span className="justify-center ml-3 lg:hidden">
                    {q261Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                {/* <p>Development and Launch of Core Platform Enhancements</p> */}
              </div>
              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q261Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      100 agricultural traders and Wholesalers
                    </span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">30k producers</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">30k consumers</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">100 FAME hubs</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      First FAME HUB international sales
                    </span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Series A $3M</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Team expansion</span>
                    <br />
                    Sales team, marketing
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">20 languages</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* 2026 2 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5 "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center z-[1]">
                <GiTwoCoins className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ262Visible, q262Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q3 - Q4
                  <span className="justify-center ml-3 lg:hidden">
                    {q262Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                {/* <p>Development and Launch of Core Platform Enhancements</p> */}
              </div>
              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q262Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      FAME farm IOT live tracker
                    </span>
                    <br />
                    soil, water, air,
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Global commodities market</span>
                    <br />
                    live prices, instant purchase, full traceability, automated
                    delivery, On Chain settlements
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="text-3xl font-bold my-5 text-secondary">
            20<span className="text-primary">27</span>
          </p>
          {/* 2027 1 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5 "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-5 flex justify-center items-center z-[1]">
                <BsCurrencyExchange className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-5">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ271Visible, q271Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q1 - Q2
                  <span className="justify-center ml-3 lg:hidden">
                    {q271Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                {/* <p>Development and Launch of Core Platform Enhancements</p> */}
              </div>
              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q271Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      500 agricultural traders and Wholesalers
                    </span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">100k producers</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">100k consumers</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">500 FAME HUBS</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      50 FAME HUB international sales
                    </span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">FAME food brand</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      FAME organic and ethical shops globally
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* 2027 2 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5 "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center z-[1]">
                <MdRocketLaunch className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div
                className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4"
                onClick={() => toggleVisibility(setQ272Visible, q272Visible)}
              >
                <p className="font-bold inline-flex items-center">
                  Q3 - Q4
                  <span className="justify-center ml-3 lg:hidden">
                    {q272Visible ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </p>
                {/* <p>Development and Launch of Core Platform Enhancements</p> */}
              </div>
              <div
                className={` flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 lg:block ${
                  q272Visible ? "block" : "hidden"
                } `}
              >
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      1000 agricultural traders and Wholesalers
                    </span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">300k producers</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">300k consumers</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">1000 FAME HUBS</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      200 FAME HUB international sales
                    </span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">10 shops in 5 continents</span>
                  </p>
                </div>
                <div className="inline-flex w-full">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      FAME is a global ethical and organic food brand
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <iframe
        src="https://widgets.sociablekit.com/medium-publication-feed/iframe/25439264"
        frameborder="0"
        width="100%"
        height="1000"
      ></iframe> */}

      {/* <section>
        <Form />
      </section> */}
    </section>
  );
}
