import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { MdInstallMobile } from "react-icons/md";

const NavLinks = () => {
  const [click, setClick] = useState(false);

  const closeMobileMenu = () => setClick(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleDownloadApp = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // Redirect to Google Play Store
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.fame.app&hl=en&gl=US";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirect to Apple App Store (iOS Devices)
      window.location.href =
        "https://apps.apple.com/us/app/algofame/id6461848634";
    } else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
      // Redirect MacBooks and macOS devices to Apple App Store
      window.location.href =
        "https://apps.apple.com/us/app/algofame/id6461848634";
    } else {
      // Redirect all other laptops/desktops to Google Play Store
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.fame.app&hl=en&gl=US";
    }
  };

  return (
    <>
      <li className="nav-item sm-items">
        <NavLink
          to="/"
          className={({ isActive }) =>
            "nav-links" + (isActive ? " activated" : "")
          }
          onClick={closeMobileMenu}
        >
          home<span className="dot">.</span>
        </NavLink>
      </li>
      <li className="nav-item last sm-items">
        <NavLink
          to="/roadmap"
          className={({ isActive }) =>
            "nav-links" + (isActive ? " activated" : "")
          }
          onClick={closeMobileMenu}
        >
          roadmap<span className="dot">.</span>
        </NavLink>
      </li>
      <li className="nav-item last sm-items">
        <NavLink
          to="/about"
          className={({ isActive }) =>
            "nav-links" + (isActive ? " activated" : "")
          }
          onClick={closeMobileMenu}
        >
          about<span className="dot">.</span>
        </NavLink>
      </li>
      <li className="nav-item sm-items">
        <NavLink
          to="/blog"
          className={({ isActive }) =>
            "nav-links" + (isActive ? " activated" : "")
          }
          onClick={closeMobileMenu}
        >
          blog<span className="dot">.</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          to="/dao"
          className={({ isActive }) =>
            "nav-links" + (isActive ? " activated" : "")
          }
          onClick={closeMobileMenu}
        >
          DAO<span className="dot">.</span>
        </NavLink>
      </li>
      <li className="nav-item last sm-items relative inline-block text-left">
        <Menu>
          <div>
            <MenuButton className="inline-flex w-full text-white text-sm ml-2.5 lg:ml-0">
              leagues <span className="dot">.</span>
              <ChevronDownIcon
                className=" h-5 w-5 text-black"
                aria-hidden="true"
              />
            </MenuButton>
          </div>
          <MenuItems
            transition
            className="absolute left-0 z-10 mt-2 w-40 origin-top-right text-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-10 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="py-1">
              <MenuItem>
                {({ focus }) => (
                  <NavLink
                    to="/leagues/fame"
                    className={classNames(
                      focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Fame League
                  </NavLink>
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <NavLink
                    to="/leagues/algorandfrance"
                    className={classNames(
                      focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    French League
                  </NavLink>
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <NavLink
                    to="/leagues/enuguleague"
                    className={classNames(
                      focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Enugu League
                  </NavLink>
                )}
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
      </li>
      <li className="nav-item lg:block hidden">
        <a
          className="bg-white rounded-lg px-4 text-secondary inline-flex items-center cursor-pointer"
          onClick={handleDownloadApp}
          target="_blank"
        >
          Install App
          <span className="ml-1">
            <MdInstallMobile />
          </span>
        </a>
      </li>
    </>
  );
};

export default NavLinks;
