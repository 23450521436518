import React, { useState } from "react";
import { FadeIn } from "react-slide-fade-in";
import Partner1 from "../Assets/Img/partner1.png";
import Partner2 from "../Assets/Img/partner2.png";
import Partner3 from "../Assets/Img/partner3.png";
import Partner4 from "../Assets/Img/partner4.png";
import Partner5 from "../Assets/Img/partner5.png";
import Partner6 from "../Assets/Img/partner6.png";
import Partner7 from "../Assets/Img/partner7.png";
import Partner8 from "../Assets/Img/partner8.png";
import Partner9 from "../Assets/Img/partner9.png";
import Partner11 from "../Assets/Img/partner11.png";
import Partner12 from "../Assets/Img/partner12.png";
import Person1 from "../Assets/Img/person1.jpg";
import Person2 from "../Assets/Img/person22.jpg";
import Person3 from "../Assets/Img/person2.jpg";
import Person4 from "../Assets/Img/person4.png";
import Person6 from "../Assets/Img/person6.png";
import Person81 from "../Assets/Img/person8.png";
import Person9 from "../Assets/Img/person9-2.png";
import Person11 from "../Assets/Img/person11.png";
import Person12 from "../Assets/Img/person12.png";
import Person14 from "../Assets/Img/person14.png";
import Person15 from "../Assets/Img/person15.png";
import Person17 from "../Assets/Img/person17.png";
import Person19 from "../Assets/Img/person19.png";
import Person20 from "../Assets/Img/person20.png";
import Person23 from "../Assets/Img/person23.png";
import { MdRocketLaunch } from "react-icons/md";
import { FaChevronUp, FaChevronDown } from "react-icons/fa6";
import { FaBuilding, FaCheck, FaTruck } from "react-icons/fa";
import { GiTwoCoins, GiTreeBranch, GiGroundSprout } from "react-icons/gi";
import Team from "../Components/Team";
// import { useTimer } from "../Controller/Constants";
export default function About() {
  const partners = [
    {
      name: "Algorand Foundation",
      link: "https://www.algorand.foundation/",
      image: Partner1,
    },
    {
      name: "Blockchain Supply Chain Association - Global",
      link: "https://www.bsca.global/",
      image: Partner2,
    },
    {
      name: "LE 101",
      link: "https://www.cm101innovation.fr",
      image: Partner4,
    },
    {
      name: "Hôtel Entreprises",
      link: "https://www.cm101innovation.fr",
      image: Partner5,
    },
    {
      name: "Fabrique de Territoire",
      link: "https://www.cm101innovation.fr/la-fabrique-de-territoire/",
      image: Partner6,
    },
    {
      name: "BC100+",
      link: "https://bc100plus.org/",
      image: Partner7,
    },
    {
      name: "Unlimited Crypto",
      link: "https://www.unlimit.com/",
      image: Partner8,
    },
    {
      name: "EURS by Stasis",
      link: "https://eurs.stasis.net/",
      image: Partner9,
    },
    {
      name: "Quantoz Payments",
      link: "https://quantozpay.com/",
      image: Partner11,
    },
    {
      name: "Le Village by CA",
      link: "https://levillagebyca.com/en/",
      image: Partner12,
    },
    {
      name: "Blockchain for Good",
      link: "https://blockchainforgood.fr/",
      image: Partner3,
    },
  ];
  return (
    <section>
      <section>
        <div
          className="isolate bg-white hero-about lg:bg-center md:bg-top bg-[url('./Assets/Img/about-bg.jpg')] bg-center"
          style={{ backgroundRepeat: "no-repeat" }}
        >
          <main className="" style={{ maxWidth: "1400px", margin: "0 auto" }}>
            <div className="">
              <div className="p-10 lg:p-20 md:p-20 ">
                <div>
                  <div className="pt-20">
                    <FadeIn
                      from="left"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <p className="text-white lg:pb-24 md:pb-0 pb-20 lg:pt-0 md:pb-0 pt-10 text-right hero-heading">
                        who we are.
                      </p>
                    </FadeIn>
                    {/* <FadeIn
                      from="right"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <h6 className="hero-list mt-10 text-right text-white hidden md:block lg:block">
                        <span>work.</span>
                        <span> sell.</span>
                        <span> borrow.</span>
                        <span> earn.</span>
                        <span> CONNECT.</span>
                      </h6>
                    </FadeIn> */}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </section>
      {/* <section
        className="lg:p-20 p-10 grid lg:grid-cols-2 md:grid-cols-2 gap-5"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      > */}
      <section
        className="lg:p-20 p-10 gap-5"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <FadeIn
          from="left"
          positionOffset={400}
          triggerOffset={200}
          delayInMilliseconds={0}
        >
          <div className=" lg:pr-5 md:pr-5">
            {/* <p className="text-bold">
              FAME is a Regenerative Finance (ReFi) initiative
            </p> */}
            <p className="text-heading">
              about <span className="text-primary">FAME</span>
            </p>
            <p className="text-thin mt-5">
              FAME is a diverse team of farmers, wholesalers, technologists,
              cybersecurity experts, and WEB3 specialists, united under the
              visionary leadership of a philosopher and UN Food Security
              Commissioner. Together, we leverage cutting-edge technology to
              empower sustainable agriculture, bridge global markets, and
              promote ethical trade.
            </p>
          </div>
        </FadeIn>
        {/* <FadeIn
          from="right"
          positionOffset={400}
          triggerOffset={200}
          delayInMilliseconds={0}
        >
          <div className="pb-10 lg:pl-5 md:pl-5">
            <p className="text-bold">FAME provides a social marketplace</p>
            <p className="text-thin">
              This empowers farmers, workers and consumers. Through technology
              and financial access the aim is to enable farmers to scale and
              escape from poverty, making them “famous” for their products,
              which become directly accessible to consumers who also benefit
              from better quality, fair-priced products.
            </p>
          </div>
        </FadeIn> */}
      </section>
      <section className="bg-secondary">
        <div
          style={{ maxWidth: "1400px", margin: "0 auto" }}
          className="relative py-10 lg:py-20 md:py-20"
        >
          <p className="partner-text pl-10 lg:pl-20 md:pl-20 ">
            partner <br /> <span className="text-white">organizations</span>
          </p>
          <div className="pt-10 relative overflow-hidden">
            {/* Left Gradient */}
            <div className="absolute top-0 left-0 h-full w-screen lg:w-40 lg:bg-gradient-to-r lg:from-secondary lg:to-transparent pointer-events-none z-10"></div>

            {/* Scrolling Content */}
            <div className="flex flex-row animate-loop-scroll gap-10">
              {Array.from({ length: 10 }, () => partners)
                .flat()
                .map((partner, index) => (
                  <div className="w-[150px] flex-shrink-0" key={index}>
                    <a
                      href={partner.link}
                      target="_blank"
                      rel="noreferrer"
                      className="block w-[150px]"
                    >
                      <div className="h-[150px] items-center flex">
                        <img
                          src={partner.image}
                          alt={partner.name}
                          className="w-[150px] h-auto object-cover"
                        />
                      </div>
                      <p className="subtitle mt-3 text-center">
                        {partner.name}
                      </p>
                    </a>
                  </div>
                ))}
            </div>

            {/* Right Gradient */}
            <div className="absolute top-0 right-0 h-full w-screen lg:w-40 lg:bg-gradient-to-l lg:from-secondary lg:to-transparent pointer-events-none z-10"></div>
          </div>
        </div>
      </section>

      <div
        id="retainable-rss-embed"
        data-rss="https://medium.com/feed/retainable,
https://medium.com/feed/vue-mastery"
        data-maxcols="3"
        data-layout="grid"
        data-poststyle="inline"
        data-readmore="Read the rest"
        data-buttonclass="btn btn-primary"
        data-offset="-100"
      ></div>

      <section
        className="p-10 lg:p-20 md:p-20"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <p className="text-heading">
          the FAME <span className="text-primary">team</span>
        </p>
        <div className="grid lg:grid-cols-3 pt-10 gap-10">
          <Team
            person="Bilal Brahim"
            position="Founder"
            position2="BLockchain expert Commissioner for food security, IEMO (UN)"
            picture={Person1}
            content1="Bilal Brahim, FAME’s founder and CEO, serves as Commissioner for Food Security at IEMO, a UN-affiliated organization, where he develops scalable solutions to prevent humanitarian crises."
            content2="With a background as a philosophy teacher and entrepreneur, Bilal brings a unique blend of global perspective and local impact, leveraging Web3 technology to combat inequalities and foster food transparency."
            content3=""
            url="https://www.linkedin.com/in/bilal-brahim/"
          />

          <Team
            person="Valentijn Van Wezer"
            position="COO"
            position2="Business Analyst"
            picture={Person2}
            content1="Valentijn is an innovative leader with a deep commitment to sustainable agricultural and economic solutions in Africa. He and his family run a safari company in Tanzania, reflecting their passion for African wildlife and dedication to preserving natural ecosystems. Additionally, they are establishing various potato farming projects in Tanzania, Ghana and palm oil cultivation across Africa, showcasing their dedication to forward-thinking agricultural initiatives and community-based projects that align with the FAME mission of food transparency, financial inclusion, and sustainable practices."
            content2="With 25 years of experience at industry giants like BASF, Total, Esso, and Imerys Carbon Black, Valentijn blends strategic and altruistic approaches in his work. His expertise in these global companies has equipped him with deep analytical insight and a human-centric approach, enabling him to create efficient, market-leading solutions that drive economic empowerment and community development."
            content3="Valentijn's work is characterized by his ability to innovate and implement sustainable practices that benefit both local communities and the broader ecosystem. His leadership and vision align perfectly with FAME's mission to revolutionize the agricultural sector through the use of blockchain technology, promoting financial inclusion, and fostering decentralized governance. Valentijn's experience and dedication to sustainable development make him a valuable addition to the FAME leadership team."
            url="https://www.linkedin.com/in/valentijn-van-wezer-684474183/"
          />
          <Team
            person="Christopher Williams"
            position="CMO"
            // position2="Business Analyst"
            picture={Person4}
            content1=" Christopher is a digital media producer with experience in marketing and education."
            content2="With a passion for blockchain technology and financial inclusion, he focuses on building connections within communities and empowering others through financial literacy."
            url="https://www.linkedin.com/in/christopher-williams-952604191/"
          />
          <Team
            person="Emmanuel Charles"
            position="Software Developer"
            position2="AI"
            picture={Person6}
            content1="Emmanuel Charles is a Certified Design Engineer and Software Developer with 4 years experience building crafted usable and scalable products in SAAP, SAAS, WEB2/WEB3, Mobile Solution and others  for companies to accelerate their growth."
            content2="Emmanuel's programming skills includes Java, Reach Blockchain, Dart(Flutter), Python(Automation) and JavaScript(Native) highly skilled in Design solution, Mobile app development , Data visualization, Blockchain development, Ethical hacking and Hardware Automation, currently building at ALGOFAME, he lives in Lagos, Nigeria."
            content3=""
            url="https://www.linkedin.com/in/emmanuelpcharles/"
          />
          <Team
            person="Abdulrazaq Oladapo"
            position="Tech Lead"
            position2="Blockchain"
            picture={Person81}
            content1="Abdulrazaq is a Mechatronics Engineer and a senior software developer with more than 6 years experience and currently working alongside the executive team of ALGOFAME. "
            content2="Abdulrazaq specializes in mobile application development technology (Flutter) and is responsible for building FAME web3 farmers social solution and also an active enthusiast on ALGORAND blockchain."
            content3="Abdulrazaq has many computer programming skills which include Javascript(NodeJs), Dart(Flutter), C++(IOT) and Java(Native) and has built multiple applications on these platform languages. He currently lives in Nigeria, Ekiti State."
            url="https://www.linkedin.com/in/oladapo-abdulrazaq-6028a4206/"
          />
          <Team
            person="Aholu Samuel"
            position="Blockchain Developer"
            position2="DAOs Manager"
            picture={Person9}
            content1="Samuel is a Tech-savvy Web Developer with over 5 years experience, who contributes specially in search engine optimization -  to launch custom-tailored code for websites presence, detailed understanding of hands-on approach to customizing web presence strategy. Resourceful navigation of intricate factors governing web planning, security, Hosting and SEO."
            content2="Samuel's programming skills includes CSS, JavaScript (Vanilla, React & Nodejs), PHP (Laravel), C++(IOT). He currently lives in Ekiti State, Nigeria."
            content3=""
            url="https://www.linkedin.com/in/samuel-aholu-b242711a3/"
          />
          <Team
            person="Ignacio Fernandez"
            position="Designer"
            position2="UX/Graphic"
            picture={Person11}
            // content1="Chris runs his own branding and web-design studio in the UK - tallhat.com. With a degree in graphic design he worked for a large digital agency in London as 'Head of Design', producing creative work for brands such as Guinness, Peugeot and Microsoft. He is highly visual and ideas-led, working with organizations to help communicate their offerings to customers. He develops brands, marketing materials and designs websites for a wide variety of clients."
            // content2="Chris discovered Algorand in early 2021 and has been exploring a few project ideas, recently launching SweeperKeeper - an unofficial World Cup NFT project - to test the water."
            // content3=""
            url="https://www.linkedin.com/in/ignaciofernandezdesign/"
          />
          {/* <Team
            person="Eyitayo Anjorin"
            position="Head of Nigeria"
            picture={Person11}
            content1="Eyitayo Anjorin is a seasoned professional with a background in the blockchain industry, including experience as a community manager, blockchain engineer, and blockchain advocate."
            content2="He has been active in the industry since 2016 and has a strong understanding of both the technical and business aspects of blockchain technology. In addition to his expertise in blockchain, Eyitayo is proficient in both writing and coding. He is a well-rounded and highly skilled individual who is dedicated to driving success within the blockchain industry."
            content3=""
          /> */}
        </div>
      </section>
      <section className=" bg-tertiary">
        <div
          className="p-10 lg:p-20 md:p-20"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <p className="text-heading text-white">
            the fame <span className="text-secondary">advisors</span>
          </p>
          <div className="grid lg:grid-cols-3 pt-10 gap-10 text-grey">
            <Team
              person=" Christina Stelzl"
              position="Agricultural trader MBA"
              picture={Person19}
              content1="Christina Stelzl is a seasoned professional specialized in business strategy and food supply chain management. With 20 years of experience in the food supply chain sector, her career began in logistics and retail business, transitioning later to significant roles in wholesale and retail companies in Austria and Germany."
              content2="In 2023, she founded her own trading and consulting firm based in Austria, offering expert consultation and project execution along the food supply chain to assist businesses in refining their purchasing or sales strategies and exploring new markets."
              content3="Christina earned her MBA in Management and Business Strategy from Hochschule für Angewandte Wissenschaften, Munich, with a thesis on sustainability labels in the German food industry, and a Bachelor of Arts in Business with a concentration in Logistics and Supply Chain Management from Fachhochschule des BFI, Vienna. Fluent in German and English, she excels in fostering strategic partnerships and brings expertise in ecological agriculture and supply chain management."
              content4=""
              url="https://www.linkedin.com/in/christina-stelzl-a455685a/"
            />
            <Team
              person="Liran Peretz"
              position="Agricultural expert"
              picture={Person15}
              content1="He is the co-founder and CEO of Alty, the all-in-one platform powering the world’s best crypto communities."
              content2="Before founding Alty, he worked in the digital finance & gaming sectors and oversaw the finance department at Playtech and managed the development of skilled teams in Antigua and the Philippines. He is an early crypto adopter, entering the space in 2011. Over the past 11 years, he has been actively engaged in the cryptocurrency community as a supporter and investor.
              Beyond his interests in crypto and finance, he has deep roots in commercial agriculture. Prior to his crypto and tech roles, he was a prominent figure in the regional agriculture segment, known for introducing Israeli drip and fertigation technology systems across the Caribbean region."
              content3="He is passionate about community development projects, the implementation of new technologies, and kitesurfing."
              url="https://www.linkedin.com/in/liranp1/"
            />

            {/* <Team
              person="Mokua (Erickson) Mogendi"
              position="Agritech expert"
              picture={Person16}
              content1="He believes technology can improve food security world wide, a generalist in Agricultural sector with all round skills in production in various enterprises within farming."
              content2="Livestock management,Crop management, Aquaculture management above all farm planning,budgeting and implementation.
              With a well resource mobilization and good structured agricultural policies, he believes we are able to feed ourselves without struggle."
              content3="Vertical farming, Aquaponics,Hydroponics,Greenhouse, among others can take us there."
            /> */}
            {/* <Team
              person="Andrea Frosinini"
              position="Supply Chain expert"
              picture={Person14}
              content1="I am a Senior Trade Finance professional, who mainly held this position in corporate  contexts, with a profound passion for innovation and digitisation. I recently had the opportunity to look at my job under a futuristic perspective since, in January 2020, I was asked to join Hyperledger community as Chairperson of Hyperledger Trade Finance SIG with the aim at offering a contribution to the industry modernization and development of new products exploiting on blockchain and DLTs, more in general. So far, I have managed to create a sound, cohesive network of professionals and companies operating in the field of enterprise blockchain solutions for Digital Trade & Supply Chain Finance, that is now giving the rise to some interesting projects and PoCs, built on different blockchain networks."
              content2="In late september 2021, I was hired by XDC Foundation as Business Development Manager. In this position, I managed to expand the community of developers and also gave rise to a series of project under the umbrella of several international institutions such as ITFA, with its, DNI-Digital Negotiable Initiative and TFDI  and TradeFinance Distribution Initiative,  ICC Singapore DSI-Digital Standards Initiative and BAFT in the US."
              content3="This has so far allowed me to further develop my experience of matters related not only to trade finance instruments and procedures, but, most importantly, on how these are being impacted by current business dynamics and digital transformation, with an eye always set on the market trends and developments at global scale."
              url="https://www.linkedin.com/in/andreafrosinini/"
            /> */}
            <Team
              person="Stéphane Barroso"
              position="ARC manager Algorand foundation"
              picture={Person3}
              content1="An experienced software engineer with expertise spanning payments, industry, military, and finance, specializing in blockchain development. He is passionate about building high-quality, scalable, and secure applications."
              content2="Stéphane has worked with numerous corporations and has a strong passion for solving complex technical problems and delivering polished solutions. He firmly believes in the power of blockchain technology to revolutionize how we interact with commodities in an increasingly digital world."
              content3=""
              url="https://www.linkedin.com/in/stephanebarroso/"
            />
            {/* <Team person="MG" position="Technology Advisor" picture={Person5} /> */}
          </div>
        </div>

        <div className="pt-10 pb-10"></div>
      </section>
      <section
        className="p-10 lg:p-20 md:p-20"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <p className="text-heading">
          the FAME <span className="text-primary">ambassadors</span>
        </p>
        <div className="grid lg:grid-cols-3 pt-10 gap-10">
          <Team
            person="Judith"
            position="Community Manager in Kenya"
            picture={Person17}
            content1="My name is Judith from kenya and I am a Farmer,I am hard working and dedicated young farmer despite having a rare neurological condition(Myasthenia gravis)that doesn't stop me from the love I have for the land."
            content2="I was born and raised into a farming family and from a young age I was taught  the importance of taking care of the land and everything on it and I fell in love with the life of a Farmer which am doing upto now."
            content3=""
          />
          <Team
            person="Carlos Lima"
            position="FAME Ambassador in Portugal"
            picture={Person20}
            content1="Since 1999, Carlos Lima has taught Physics and Chemistry, earning his degree from the University of Minho in Braga, Portugal. He's spearheaded numerous initiatives to popularize science and has served as the vice-president of the Minho Scientific Astronomy Association since 2018."
            content2=" In addition to his academic pursuits, Carlos has been an advocate for blockchain technology since 2020, focusing especially on the Algorand project. As of August 2023, he is the FAME Ambassador for Portugal and Portuguese-speaking nations."
            content3=""
          />
          <Team
            person="Edward"
            position="FAME Ambassador"
            picture={Person23}
            content1="Edward is a dynamic Blockchain Developer Lead specializing in Ethereum solutions for renewable energy initiatives. At Unergy.io, he plays a vital role in driving investments in green energy across Colombia. "
            content2=" His experience includes web development for the Financial Laboratory Group at the National University of Colombia and IT consulting for SENA, where he supported digital transformation in healthcare."
            content3=" With a degree in Systems Engineering and Informatics from Universidad Nacional de Colombia and a background in astronomy, Edward combines technical expertise with a passion for sustainable innovation."
          />
          <Team
            person="Solomon Agboeke"
            position="FAME Ambassador in Nigeria"
            picture={Person12}
            content1="Agboeke Solomon Friday, a native of Umu-Egwu Neke in Isi Uzo Local Government Area of Enugu State, Nigeria, is a passionate agricultural entrepreneur and blockchain enthusiast with an impressive background in community service and leadership.Solomon’s educational journey began at Nativity Academy in Neke for nursery and primary education, after which he earned a scholarship to attend Saint Teresa’s College, Nsukka, for his secondary education, a testament to his early dedication and academic potential."
            content2="A pioneer in merging agriculture with blockchain technology, Solomon has collaborated on various blockchain projects, including his role as a validator with Agrichaix and as a pioneer and coordinator with Zuga Coin. His contributions to the blockchain space reflect his commitment to driving innovation and growth within his community and beyond."
            content3=" Currently, Solomon is the head of administration and security chapter lead at Ohaneze Farms and Resort in Akpukpa-Aba, where he supports and oversees farm operations, helping to create job opportunities and drive agricultural advancements in his community. He is also a proactive member of the Enugu State Young Farmers Association (ENSYFA) and serves as the secretary for the Isi Uzo chapter. As the founder and CEO of Enugu Organics, Solomon remains committed to sustainable agriculture, supporting local farmers, and promoting organic farming practices in Enugu State and across Nigeria. His vision and leadership continue to inspire those around him, making him a key figure in the agricultural and blockchain sectors."
          />
        </div>
      </section>

      {/* <section>
        <Form />
      </section> */}
    </section>
  );
}
